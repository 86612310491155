




























































import Vue from 'vue';
import { v4 } from 'uuid';
import { getCaptcha } from '@/api/auth';
import mixin from './mixin';

function toURL(data: Blob) {
  const blob = new Blob([data]);
  return window.URL.createObjectURL(blob);
}

function revoke(url: string) {
  window.URL.revokeObjectURL(url);
}
export default Vue.extend({
  mixins: [mixin],
  data() {
    const validUsername = async (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'));
      }
    };
    return {
      formState: {
        username: '',
        password: '',
        grant_type: 'password',
        scope: 'all',
        captcha: '',
        uuid: '',
      },
      rules: {
        username: [
          {
            required: true,
            validator: validUsername,
            validateTrigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            validateTrigger: 'blur',
          },
          {
            max: 20,
            min: 6,
            message: '密码长度为6-20个字符',
          },
        ],
        captcha: [
          {
            required: true,
            message: '验证码不能为空',
            validateTrigger: 'blur',
          },
        ],
      },
      checked: false,
      imgUrl: '',
    };
  },
  watch: {
    checked(val) {
      if (!val) {
        localStorage.removeItem('username');
      } else {
        localStorage.setItem('username', this.formState.username);
      }
    },
  },
  created() {
    const username = localStorage.getItem('username') || '';
    this.checked = !!username;
    this.formState.username = username;
    this.captchaCode();
  },
  methods: {
    captchaCode() {
      this.formState.uuid = v4();
      getCaptcha(this.formState.uuid).then((res) => {
        if (this.imgUrl) {
          revoke(this.imgUrl);
        }
        this.imgUrl = toURL(res);
      });
    },
  },
});
